import React from 'react';
import Helmet from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';
import { mapBlogs } from '../../lib/utility';
import { img, imgBrand, alt as altText, isBe } from '../../lib/brand';

import Layout from '../../components/Layout';
import Header from '../../components/Header';
import HeroLarge from '../../components/HeroLarge';
import IntroBlock from '../../components/IntroBlock';
import Types from '../../components/Types';
import BgImage from '../../components/BgImage';
import BlogRoll from '../../components/BlogRoll';
import FindLO from '../../components/FindLO';
import Calculators from '../../components/Calculators';
import { refinanceIcons } from '../../lib/gtm';

// Home loan types icons
// active
var Icons_FixedRate_active = imgBrand('typesImg/Icons_FixedRate_active.png');
var Icons_Jumbo_active = imgBrand('typesImg/Icons_Jumbo_active.png');
var Icons_VA_active = imgBrand('typesImg/Icons_VA_active.png');
var Icons_AdjustableRate_active = imgBrand('typesImg/Icons_AdjustableRate_active.png');

// inactive
var Icons_FixedRate_inactive = imgBrand('typesImg/Icons_FixedRate_inactive.png');
var Icons_Jumbo_inactive = imgBrand('typesImg/Icons_Jumbo_inactive.png');
var Icons_VA_inactive = imgBrand('typesImg/Icons_VA_inactive.png');
var Icons_AdjustableRate_inactive = imgBrand('typesImg/Icons_AdjustableRate_inactive.png');

var cinemaImage = img('Refinance_edited.jpg');
var hero = img('hero.jpg', 'refinance-mortgage');

const RefinanceLoans = ({
   items = [0, 1, 2, 3],
   images = [Icons_FixedRate_active, Icons_AdjustableRate_active, Icons_VA_active, Icons_Jumbo_active],
   blueImages = [Icons_FixedRate_inactive, Icons_AdjustableRate_inactive, Icons_VA_inactive, Icons_Jumbo_inactive],

   captions = ['Fixed-Rate Mortgage', 'Adjustable-Rate Mortgage', 'VA Refinance (IRRRL)', 'Jumbo Loans'],

   infos = [
      'Fixed-rate mortgages are the standard in home loans and are available in a variety of terms from 10 to 30 years. As the name suggests, these loans offer a consistent, fixed payment throughout the life of the loan.',
      'Adjustable-rate mortgages are a great fit for homebuyers who plan to sell or refinance their home between 5 and 10 years because they offer the lowest initial rate available.',
      'Veterans Affairs (VA) refinances are available exclusively to people who have served in the armed services and their surviving spouses. They have flexible qualification guidelines, plus other features that are not available with other programs.',
      'Sometimes big goals require big loans. For home mortgages of $510,400 and larger ($765,600 for AK and HI), jumbo loans can help you get into your dream home.'
   ],
   links = ['/fixed-rate-mortgage', '/adjustable-rate-mortgage', '/va-refinance', '/jumbo-loan'],
   alt = [
      'Fixed Rate Mortage Icon - Refinance Mortage',
      'Adjustable Rate Mortage Icon - Refinance Mortage',
      'VA Refinance (IRRRL) Icon - Refinance Mortage',
      'Jumbo Loan Icon - Refinance Mortage'
   ].map(altText)
}) => {
   const data = useStaticQuery(graphql`
      query RefinanceLoansPageQuery {
         corpBlogs: allBlog(
            sort: { fields: [date], order: DESC }
            filter: { type: { eq: "corporate" }, tags: { in: ["Refinance"] } }
         ) {
            edges {
               node {
                  id
                  html
                  slug
                  title
                  date
                  feature
                  categories
                  image
                  author {
                     slug
                     profile {
                        name
                     }
                     gallery {
                        photo
                     }
                  }
                  date
                  feature
               }
            }
         }
      }
   `);
   const intro = 'What is a home loan refinance?';
   const par =
      'A mortgage refinance allows homeowners to obtain better interest rates and terms by negotiating a new mortgage that replaces the original. Through a refinance loan, homeowners can better customize their mortgage rates, their loan length and even how much money is being borrowed.';

   const { corpBlogs } = data;
   const { edges: corpBlobPosts = [] } = corpBlogs || {};
   const corporateBlogs = mapBlogs(corpBlobPosts);

   const props = {
      items,
      images,
      blueImages,
      links,
      captions,
      infos,
      title: 'Different ways to refinance',
      gtm: refinanceIcons,
      alt
   };

   const header = <Header active={['current-homeowner', 'refinance-mortgage']} />;

   return (
      <Layout hasHero={true} header={header}>
         <Helmet>
            <title>{altText('Refinance Mortgage - Refinance Home Loan')}</title>
            <meta
               name="description"
               content="Refinancing a mortgage allows homeowners to negotiate a new mortgage agreement, turning the equity in their home into cash. Contact us today to find out if you qualify."
            />
            <script
               src={`https://embed.signalintent.com/js/embedded.js?org-guid=${process.env.GATSBY_SIGNAL_INTENT_ORG_GUID}`}></script>
         </Helmet>
         <HeroLarge customPhoto={hero} />
         <IntroBlock header={intro} par={par} />
         <Types {...props} />
         {isBe && (
            <>
               <BgImage bg={cinemaImage} />
               <Calculators showCalculatorRefinance={true} />
               <FindLO />
               {corporateBlogs.length >= 3 && (
                  <BlogRoll
                     corporateBlogs={corporateBlogs}
                     blogs={corporateBlogs}
                     showBlogs={true}
                     word="our"
                     url="refinance-mortgage"
                  />
               )}
            </>
         )}
      </Layout>
   );
};
export default RefinanceLoans;
